.state__table {
    border-spacing: 3px;
    border-collapse: separate;
}

.icon {
    cursor: pointer;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
}

.icon:hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
}

.vaccine.graph {
    height: 400px;
    width: 800px;
}

@media only screen and (max-width: 460px) {
    .state__table {
        border-spacing: 0px;
        border-collapse: collapse;
    }
}

@media only screen and (min-width: 360px) {
    .vaccine.graph {
        width: 340px;
        height: 170px;
    }
}

@media only screen and (min-width: 400px) {
    .vaccine.graph {
        width: 380px;
        height: 190px;
    }
}

@media only screen and (min-width: 460px) {
    .vaccine.graph {
        width: 440px;
        height: 220px;
    }
}

@media only screen and (min-width: 768px) {
    .vaccine.graph {
        width: 700px;
        height: 350px;
    }
}

@media only screen and (min-width: 960px) {
    .vaccine.graph {
        width: 800px;
        height: 400px;
    }
}
